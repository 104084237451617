import React, { useRef } from 'react';
import { Grid } from '@tmap/mmm-style-guide/src/Grid';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@tmap/mmm-style-guide/src/Icon';
import { IconButton } from '@tmap/mmm-style-guide/src/IconButton';
import { styled } from '@tmap/mmm-style-guide/src/styled';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';

import imageUrlBuilder from '@sanity/image-url';
import client from '../client';
import LazyLoad from 'react-lazyload';

const builder = imageUrlBuilder(client);

const imgStyles = () => ({
  height: '60px',
  width: '150px',
  objectFit: 'scale-down',
});
const StyledPicture = styled('picture')(imgStyles);
const StyledImg = styled('img')(imgStyles);

const ArrowGrid = styled(Grid, {
  shouldForwardProp: (p) => p !== 'hidden',
})(({ theme, hidden }) => ({
  '&': hidden ? { display: 'none' } : {},
  zIndex: 1,
  '&:first-of-type': {
    paddingLeft: theme.spacing(6),
  },
  '&:last-of-type': {
    paddingRight: theme.spacing(6),
  },
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}));

const SwiperGrid = styled(Grid, {
  shouldForwardProp: (p) => p !== 'collapseMargins',
})(({ theme, collapseMargins }) => ({
  width: '100%',
  '&': collapseMargins ? {
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(-14),
      marginRight: theme.spacing(-14),
      paddingLeft: theme.spacing(14),
      paddingRight: theme.spacing(14),
    },
  } : {},

  '& .swiper': {
    width: '100%',
  },

  '& .swiper-wrapper': {
    alignItems: 'center',
  },

  '& .swiper-slide': {
    '&': collapseMargins ? {
      paddingLeft: '10%',
      paddingRight: '10%',
      [theme.breakpoints.up('lg')]: {
        paddingLeft: '15%',
        paddingRight: '15%',
      },
    } : {
      paddingLeft: '2%',
      paddingRight: '2%',
    },
    maxWidth: 'min(150px, 35%)',
    marginRight: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

function PressHitItem(props) {
  const { hit } = props;
  // double actual for better quality
  const height = 120;
  const width = 300;
  return (
    <a href={hit.urlSource} aria-label={hit.image.alt} {...(hit.urlSource.toLowerCase().indexOf('makemymove.com') < 0 ? { target: '_blank' } : {})}>
      <StyledPicture>
        <source srcSet={builder.image(hit.image).format('webp').height(height).url()} />
        <StyledImg
          src={builder.image(hit.image).height(height).url()}
          alt={hit.image.alt}
          height={height}
          width={width}
        />
      </StyledPicture>
    </a>
  );
}

function PressReferences(props) {
  const { pressHits, condensed = false, lazy = false, lazyProps = {} } = props;
  const swiperRef = useRef(null);

  const LazyWrapper = lazy ? LazyLoad : React.Fragment;
  const lazyWrapperProps = lazy ? {
    height: '66.5px',
    offset: 500,
    ...lazyProps,
  } : {};

  return (
    <LazyWrapper {...lazyWrapperProps}>
      <Grid container direction='row' alignItems='center'>
        <ArrowGrid item hidden={condensed}>
          <IconButton
            variant='contained'
            size='large'
            onClick={() => swiperRef.current.swiper.slidePrev()}
            aria-label='Scroll Press Hits Back'
          >
            <KeyboardArrowLeft fontSize='large' />
          </IconButton>
        </ArrowGrid>
        <SwiperGrid item collapseMargins={!condensed}>
          <Swiper
            ref={swiperRef}
            slidesPerView='auto'
            modules={[Navigation]}
          >
            {pressHits.map((hit) => (
              <SwiperSlide key={hit._key}>
                <PressHitItem hit={hit} />
              </SwiperSlide>
            ))}
          </Swiper>
        </SwiperGrid>
        <ArrowGrid item hidden={condensed}>
          <IconButton
            variant='contained'
            size='large'
            onClick={() => swiperRef.current.swiper.slideNext()}
            aria-label='Scroll Press Hits Forward'
          >
            <KeyboardArrowRight fontSize='large' />
          </IconButton>
        </ArrowGrid>
      </Grid>
    </LazyWrapper>
  );
}

export default PressReferences;