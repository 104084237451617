import Head from "next/head";
import pageTitleFormatter from "../lib/pageTitleFormatter";
import React from "react";


const Metatags = (props) => {
  const {
    title,
    description,
    canonical,
    image,
    video,
    titleFormatter = pageTitleFormatter,
    next,
    prev,
  } = props;
  return <Head>
    <title>{titleFormatter(title)}</title>
    <meta name="referrer" content="always" />
    <meta name="title" content={titleFormatter(title)} />
    {description && <meta name="description" content={description} />}
    <link rel="canonical" href={canonical} />
    {next && <link rel="next" href={next} />}
    {prev && <link rel="prev" href={prev} />}

    <meta property="og:type" content="website" />
    <meta property="og:url" content={canonical} />
    <meta property="og:title" content={titleFormatter(title)} />
    <meta property="og:description" content={description} />
    {image && <meta property="og:image" content={image} />}
    {video && <meta property="og:video" content={video} />}
    makemymoveusa
    <meta property="twitter:card" name="twitter:card" content="summary_large_image" />
    <meta property="twitter:site" name="twitter:site" content="@makemymoveusa" />
    <meta property="twitter:url" name="twitter:url" content={canonical} />
    <meta property="twitter:title" name="twitter:title" content={titleFormatter(title)} />
    <meta property="twitter:description" name="twitter:description" content={description} />
    {image && <meta property="twitter:image" name="twitter:image" content={image} />}
  </Head>;
}

export default Metatags;