import React from 'react';
import imageUrlBuilder from '@sanity/image-url';
import { Button } from '@tmap/mmm-style-guide/src/Button';
import { Card } from '@tmap/mmm-style-guide/src/Card';
import { Grid } from '@tmap/mmm-style-guide/src/Grid';
import { styled } from '@tmap/mmm-style-guide/src/styled';
import { TextField } from '@tmap/mmm-style-guide/src/TextField';
import { Typography } from '@tmap/mmm-style-guide/src/Typography';

import { touchEmail } from '../actions/user';
import client from '../client';

const builder = imageUrlBuilder(client);

const SANITY_MAIL_IMAGE_URL = 'https://cdn.sanity.io/images/u721u3r1/production/bdd48ef627c5dc5f7fd5b616ee9290b246daa3cc-112x80.png';

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? theme.palette.primary.veryLight : theme.palette.primary.dark,
  padding: theme.spacing(4, 9),
  borderRadius: '12px',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(6),
  },
}));
const StyledTextField = styled(TextField)(({ theme }) => ({
  '& input': {
    color: theme.palette.mode === 'light' ? theme.palette.inverted.main : theme.palette.inverted.contrastText,
  },
  '& .MuiFormHelperText-root': {
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(1),
  },
}));
// eslint-disable-next-line no-empty-pattern
const CenteredGrid = styled(Grid)(({ }) => ({
  display: 'flex',
  alignItems: 'center',
}));

function validateEmail(email) {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}

function CardAd(props) {
  const {
    leadCopy,
    subleadCopy = '',
    mobile = false,
  } = props;

  const [placeholder, setPlaceholder] = React.useState('Email Address');
  const [email, setEmail] = React.useState('');
  const [error, setError] = React.useState(undefined);
  const handleChange = (e) => {
    setEmail(e.target.value);
    if (error && validateEmail(e.target.value)) {
      setError(undefined);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const handleClick = async (e) => {
    if (!validateEmail(email)) {
      setError('Help us help you. Please enter a valid e-mail address.');
    } else {
      await touchEmail(email);
      setPlaceholder('Subscribe with another email');
      setEmail('');
    }
  };

  const ContentGrid = subleadCopy ? Grid : CenteredGrid;

  return mobile ? (
    <StyledCard elevation={0}>
      <Grid container spacing={3} display='grid'>
        <Grid item sx={{ margin: '0 auto', display: 'flex', alignItems: 'center' }}>
          <img alt='' src={builder.image(SANITY_MAIL_IMAGE_URL).url()} />
        </Grid>
        <ContentGrid item xs={12} sx={{ textAlign: 'center' }}>
          <Typography variant='h4' fontWeight={600} width='100%'>{leadCopy}</Typography>
          {subleadCopy && <Typography variant='body1' color='textSecondary' mt={1}>{subleadCopy}</Typography>}
        </ContentGrid>
        <Grid item xs={12}>
          <StyledTextField
            name='emailSignup'
            placeholder={placeholder}
            onChange={handleChange}
            value={email}
            fullWidth
            variant='outlined'
            size='small'
            InputProps={{ style: { background: 'white', marginBottom: '16px' } }}
            error={error !== undefined}
            helperText={error}
          />
          <Button variant='contained' color='secondary' size='large' sx={{ display: 'none' }} onClick={handleClick}>Sign Up</Button>
          <Button variant='contained' color='secondary' size='large' fullWidth sx={{ display: 'block' }} onClick={handleClick}>Sign Up</Button>
        </Grid>
      </Grid>
    </StyledCard>
  ) : (
    <StyledCard elevation={0}>
      <Grid container spacing={3}>
        <Grid item sx={{ margin: { xs: '0 auto', md: 0 }, display: 'flex', alignItems: 'center' }}>
          <img alt='' src={builder.image(SANITY_MAIL_IMAGE_URL).url()} />
        </Grid>
        <ContentGrid item xs={12} md sx={{ textAlign: { xs: 'center', md: 'left' } }}>
          <Typography variant='h4' fontWeight={600}>{leadCopy}</Typography>
          {subleadCopy && <Typography variant='body1' color='textSecondary' mt={1}>{subleadCopy}</Typography>}
        </ContentGrid>
        <Grid item xs={12} md>
          <StyledTextField
            name='emailSignup'
            placeholder={placeholder}
            onChange={handleChange}
            value={email}
            fullWidth
            variant='outlined'
            size='small'
            InputProps={{ style: { background: 'white', marginBottom: '16px' } }}
            error={error !== undefined}
            helperText={error}
          />
          <Button variant='contained' color='secondary' size='large' sx={{ display: { xs: 'none', md: 'block' } }} onClick={handleClick}>Sign Up</Button>
          <Button variant='contained' color='secondary' size='large' fullWidth sx={{ display: { xs: 'block', md: 'none' } }} onClick={handleClick}>Sign Up</Button>
        </Grid>
      </Grid>
    </StyledCard>
  );
}

export default CardAd;
